<template>
  <BaseDetail :is-loading="isHandbookLoading || isServiceDataLoading || isLoading.data">
    <template
      #header
      style="position: fixed"
    >
      <BaseHeader
        @save="handleSave"
        @delete="handleDelete(0)"
        @close="handleClose"
        @restore="handleRestore"
        @dataBaseDelete="isDataBaseDelete = true"
        :restore-btn="detail && detail.properties.state === 2 && isCanInteractionObject"
        :deleteDataBaseBtn="($can('admin', null) || $can('manager', null)) && $route.params.objectId !== 'create'"
        :delete-btn="detail && detail.properties.state === 1 && isCanInteractionObject"
        :save-btn="isCanInteractionObject"
        text-delete="Вы действительно хотите удалить объект?"
        :subtitle="$t('base.updated_at') + ' ' + updatedTime"
      />
    </template>
    <template #content>
      <v-tabs
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          v-for="item in items"
          :key="item.label"
          v-show="item.show"
        >
          {{ item.label }}
        </v-tab>
        <v-tab-item
          v-for="item in items"
          :key="item.label"
        >
          <component
            :disabled="!isCanInteractionObject"
            :is="item.content"
            v-if="detail"
            v-model="detail"
            :attributes="item.attributes"
            model-name="object"
            :model-data="modelData.attributes || []"
            :model-id="Number(detail.properties.id)"
            accept-types="application/pdf, .docx, .doc, .xls, .xlsx, .jpg, .jpeg, .png"
            :geometry-type="'object'"
            @loaded="loadedGeometry"
            :key="item.label === 'Карта' ? keyMap : ''"
            :styles="styleObjectMap"
            :with-animate="false"
            nameObjectImg="objectImages"
            nameObjectDoc="objectFiles"
            with-description
          ></component>
        </v-tab-item>
      </v-tabs>
      <BaseDeleteConfirm
        v-if="detail"
        :id="Number(detail.properties.id)"
        :isDialog="isDataBaseDelete"
        entity-text="объект"
        @closeDialog="isDataBaseDelete = false"
        @delete="handleDelete(1)"
      />
    </template>
  </BaseDetail>
</template>

<script>
import BaseDetail from '@/components/base/BaseDetail'
import detail from '@/components/mixins/detail'
import DetailView
  from '@/components/views/account/passport/detail/views/passportDashboard/passportObjectDetail/DetailView'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import passportObjectCommon from '@/components/mixins/passportObjectCommon'
import objectValidation from '@/components/mixins/validation/object'
import BaseBtn from '@/components/base/UI/BaseBtn'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import DetailGroup
  from '@/components/views/account/passport/detail/views/passportDashboard/passportObjectDetail/DetailGroup'
import userInteraction from '@/components/mixins/userInteraction'
import ReportBurialCertificate
  from '@/components/views/account/map/detail/mapInfo/mapObjectInfo/ReportBurialCertificate'

export default {
  mixins: [detail, passportObjectCommon, objectValidation, userInteraction],
  name: 'PassportObjectDetail',
  components: {
    DetailGroup,
    BaseDeleteConfirm,
    HistoryLog: () => import('@/components/views/account/passport/detail/views/passportDashboard/passportObjectDetail/HistoryLog/HistoryLog'),
    BaseDocuments: () => import('@/components/base/BaseDocuments'),
    BasePhoto: () => import('@/components/base/BasePhoto'),
    DetailView,
    BaseDetail,
    BaseHeader,
    BaseBtn,
    ReportBurialCertificate,
    PassportObjectMap: () => import('@/components/views/account/passport/detail/views/passportDashboard/passportObjectDetail/PassportObjectMap')
  },
  props: {
    value: Object
  },
  data () {
    return {
      activeTab: 0,
      key: 20,
      isDataBaseDelete: false,
      keyMap: 1,
      items: [],
      params: { additionalParams: { organization_id: this.$store.getters['auth/getProfile'].organization.id } }
    }
  },
  watch: {
    activeTab: {
      handler () {
        if (this.activeTab === 2) {
          this.key += 1
        }
      }
    }
  },
  methods: {
    async loadedGeometry (data) {
      this.$set(this, 'detail', data)
      this.$set(this, 'keyMap', this.keyMap + 1)
    }
    /*async handleSave () {
      if (await this.validate()) {
        await this.save()
      }
      return true
    },*/
    /*async createObjectCopy () {
      if (!this.isLoading.data) {
        this.isLoading.data = true
        let response = await this.$store.dispatch('server/get', {
          url: this.controllerName + '/copy',
          params: { id: this.detail.properties.id }
        })
        if (response) {
          this.processResponse(response)
        }

        this.isLoading.data = false
        if (response && this.$route.params[this.modelIdName] === 'create' || this.$route.params[this.modelIdName] !== this.localDetail.id) {
          let params = {}
          params[this.modelIdName] = this.localDetail.id
          await this.$router.replace({ name: this.$route.name, params })
        }
      }
      return true
    }*/
  },
  computed: {
    groupItems () {
      const filteredObjectAttr = this.objectAttributes.filter(item => item.eavType.id === 14)
      const result = []
      filteredObjectAttr.map((item) => {
        result.push({
          label: item.label,
          attributes: item,
          content: 'DetailGroup',
          show: true
        })
      })
      return result
    },
    modelIdName () {
      return 'objectId'
    },
    controllerName () {
      return 'object'
    },
    localDetail () {
      return this.detail.properties
    },
    modelData () {
      return {
        attributes: this.objectAttributes,
        passportId: this.$route.params.id,
        entityId: this.entityId
      }
    },
    objectId () {
      return this.$route.params.objectId
    },
    styleObjectMap () {
      if (this.value?.properties?.passportEntities) {
        const id = this.detail.properties.eav_entity_id
        const findItem = this.value.properties.passportEntities.find((item) => item.eavEntity.id === Number(id))
        return { ...findItem.eavEntity.style, icon: findItem.eavEntity.icon }
      }
    },
    getCurrentRole () {
      return this.$store.getters['auth/getCurrentRole']
    }
  },
  mounted () {
    this.items = [
      {
        label: 'Параметры',
        content: 'DetailView',
        attributes: this.objectAttributes,
        show: true
      },
      ...this.groupItems,
      {
        label: 'Изображения',
        content: 'BasePhoto',
        attributes: this.objectAttributes,
        show: true
      },
      {
        label: this.$t('base.record'),
        content: 'ReportBurialCertificate',
        attributes: this.objectAttributes,
        show: this.entityId === 84
      },
      {
        label: 'Документы',
        content: 'BaseDocuments',
        attributes: this.objectAttributes,
        show: true
      },
      {
        label: 'Карта',
        content: 'PassportObjectMap',
        attributes: this.objectAttributes,
        show: true
      },
      {
        label: 'История изменений',
        content: 'HistoryLog',
        attributes: this.objectAttributes,
        show: true
      }
    ]
  }
}
</script>

<style>
</style>
